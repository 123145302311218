<template>
    <div>
        <div class="row g-2">
            <div class="col-md-6">
                <div class="form-group">
                    <div>
                    <label for="perKeluhan">Keluhan Utama<strong class="text-danger">*</strong></label>
                    <b-form-input v-model="isParent.row.arankaa_keluhan_utama" @input="onInput($event, 'keluhan-utama')" @blur="onBlur($event, 'keluhan-utama')" @focus="onFocus($event, 'keluhan-utama')" :disabled="disabled['keluhan-utama']" id="keluhan_utama" :formatter="isParent.normalText" type="text" name="perKeluhan" class="form-control" placeholder="Keluhan dari pasien" />
                    </div>
                    <small class="text-info">*Mohon tuliskan informasi waktu mulai keluhan terjadi</small><br/>

                    <p class='m-0 text-muted' v-if="disabled['keluhan-utama']">User lain sedang mengisi...</p>

                    <VValidate 
                        name="Keluhan Utama" 
                        v-model="isParent.row.arankaa_keluhan_utama" 
                        :rules="toValidate(isParent.mrValidation.arankaa_keluhan_utama)"
                    />
                </div>

                <div class="form-group">
                    <div>
                    <label for="perRiwayat">Riwayat Penyakit Sekarang</label>
                    <b-form-textarea @input="onInput($event, 'catatan-dokter')" @blur="onBlur($event, 'catatan-dokter')" @focus="onFocus($event, 'catatan-dokter')" :disabled="disabled['catatan-dokter']" id="riwayat_penyakit_sekarang" v-model="isParent.row.arankaa_riwayat_penyakit_sekarang" :formatter="normalText"  name="perRiwayat" rows="2" class="form-control" placeholder="Riwayat Penyakit Sekarang"></b-form-textarea>
                    </div>

                    <p class='m-0 text-muted' v-if="disabled['catatan-dokter']">User lain sedang mengisi...</p>

                    <VValidate 
                        name="Riwayat Penyakit Sekarang" 
                        v-model="isParent.row.arankaa_riwayat_penyakit_sekarang" 
                        :rules="toValidate(isParent.mrValidation.arankaa_riwayat_penyakit_sekarang)"
                    />
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <div>
                    <label for="perRiwayat">Riwayat Penyakit Dahulu</label>
                    <b-form-textarea @input="onInput($event, 'riwayat-penyakit-dahulu')" @blur="onBlur($event, 'riwayat-penyakit-dahulu')" @focus="onFocus($event, 'riwayat-penyakit-dahulu')" :disabled="disabled['riwayat-penyakit-dahulu']" id="riwayat" v-model="isParent.row.arankaa_riwayat_penyakit" :formatter="isParent.normalText"  name="perRiwayat" rows="2" class="form-control" placeholder="Riwayat penyakit dari pasien"></b-form-textarea>
                    </div>

                    <p class='m-0 text-muted' v-if="disabled['riwayat-penyakit-dahulu']">User lain sedang mengisi...</p>

                    <VValidate 
                        name="Riwayat Penyakit" 
                        v-model="isParent.row.arankaa_riwayat_penyakit" 
                        :rules="toValidate(isParent.mrValidation.arankaa_riwayat_penyakit)"
                    />
                </div>
            </div>
            
            <div class="col-12">
                <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Riwayat Pengobatan</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <div>
                            <label for="perRiwayat">Riwayat Pengobatan</label>
                            <b-form-textarea id="riwayat_pengobatan" v-model="isParent.row.arankaa_riwayat_pengobatan" :formatter="normalText"  name="perRiwayat" rows="2" class="form-control" placeholder="Riwayat Pengobatan"></b-form-textarea>
                            </div>

                            <span class="text-warning d-none" id="riwayat_pengobatan-typing">User Lain Sedang Mengisi ...</span>            
                            
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                        <label for="perRiwayatGunaObat">Riwayat Penggunaan Obat</label>
                            <table class="table table-sm table-bordered table-input">
                                <thead>
                                <tr>
                                    <th>Nama Obat</th>
                                    <th>Dosis</th>
                                    <th>Waktu Penggunaan</th>
                                    <th>Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.row.arankaa_riwayat_penggunaan_obat||[])" :key="k+'obat'">
                                    <td class="input-cell">
                                    <b-form-input v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['nama']"  :formatter="normalText" type="text" placeholder="Isi Nama Obat" class="form-control form-control-sm" />
                                    <VValidate 
                                        :name="'Nama Obat'+(k+1)" 
                                        v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['nama']" 
                                        :rules="{required: 1, min: 3, max: 128}"
                                    />
                                    </td>
                                    <td class="input-cell">
                                    <b-form-input v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['dosis']" :formatter="normalText" type="text" placeholder="Isi Dosis Obat" class="form-control form-control-sm" />
                                    <VValidate 
                                        :name="'Dosis Obat'+(k+1)" 
                                        v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['dosis']" 
                                        :rules="{required: 1}"
                                    />
                                    </td>
                                    <td class="input-cell">
                                    <b-form-input v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['penggunaan']" :formatter="normalText" type="text" placeholder="Isi Waktu Penggunaan Obat" class="form-control form-control-sm" />
                                    <VValidate 
                                        :name="'Waktu Penggunaan Obat'+(k+1)" 
                                        v-model="isParent.row.arankaa_riwayat_penggunaan_obat[k]['penggunaan']" 
                                        :rules="{required: 1}"
                                    />
                                    </td>
                                    <td class="input-cell text-center">
                                        <a href="javascript:;" @click="(isParent.row.arankaa_riwayat_penggunaan_obat||[]).splice(k,1)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon  text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td class="text-center" colspan="4">
                                    <a href="javascript:;" @click="addRiwayatPenggunaanObat" class="btn btn-outline-info alpha-info text-blue-800 btn-sm">
                                        <i class="mr-2 icon-plus2"></i>
                                        <span>Tambah</span>
                                    </a>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-md-7">
                        <div class="head_panel_red">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3>ALERGI </h3>
                            <div>
                            <b-form-radio-group
                                id="has_alergi"
                                @change="onInput($event, 'has-alergi')"
                                @input="changeAlergi($event)"
                                :options="Config.mr.yesNoOpt"
                                v-model="isParent.row.arankaa_has_alergi"
                            />

                            <span class="text-warning d-none" id="has_alergi-typing">User Lain Sedang Mengisi ...</span>            

                            <VValidate 
                                name="Alergi" 
                                v-model="isParent.row.arankaa_has_alergi" 
                                :rules="toValidate(isParent.mrValidation.arankaa_has_alergi)"
                            />
                            </div>
                        </div>
                        </div>
                        
                        <template v-if="isParent.row.arankaa_has_alergi == 'Y'">
                            <table class="table table-sm table-bordered">
                                <thead>
                                <tr>
                                    <th width="33%">Jenis</th>
                                    <th>Informasi Alergi</th>
                                    <th width="64"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.row.arankaa_alergi||[])" :key="k+'aler'">
                                    <td>
                                        <v-select @open="onFocus($event, 'alergi-jenis', k)" @close="onBlur($event, 'alergi-jenis', k)" @input="onInput($event, 'alergi-jenis', k)" :disabled="disabled['alergi-jenis'][k]" placeholder="Pilih Jenis" v-model="isParent.row.arankaa_alergi[k]['jenis']" :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        <p class='m-0 text-muted' v-if="disabled['alergi-jenis'][k]">User lain sedang mengisi...</p>
                                        <VValidate 
                                            :name="'Obat '+(k+1)" 
                                            v-model="isParent.row.arankaa_alergi[k]['jenis']" 
                                            :rules="{required:1}"
                                        />
                                    </td>
                                    <td>
                                        <vue-bootstrap-autocomplete
                                        @focus="onFocus($event, 'alergi-name', k)"
                                        @blur="onBlur($event, 'alergi-name', k)"
                                        :disabled="disabled['alergi-name'][k]"
                                        v-model="isParent.row.arankaa_alergi[k]['name']"
                                        :data="isParent.mAlergi" @input="searchAlergi(isParent.row.arankaa_alergi[k]['name']);onInput($event, 'alergi-name', k)"
                                        placeholder="Pilih Alergi"
                                        />
                                        <p class='m-0 text-muted' v-if="disabled['alergi-name'][k]">User lain sedang mengisi...</p>
                                        <VValidate 
                                            :name="'Obat '+(k+1)" 
                                            v-model="isParent.row.arankaa_alergi[k]['name']" 
                                            :rules="{required:1}"
                                        />
                                    </td>
                                    <td>
                                    <a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colspan="3" class="text-center">
                                    <a href="javascript:;" @click="addAlergi()" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            <span class="text-warning d-none" id="alergi-typing">User Lain Sedang Mengisi ...</span>            
                        </template>
                        <template v-else>
                            <table class="table table-sm table-bordered">
                                <thead>
                                <tr>
                                    <th width="33%">Jenis</th>
                                    <th>Informasi Alergi</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <v-select placeholder="Pilih Jenis" disabled :options="Config.mr.jenisAlergi" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    </td>
                                    <td>
                                    <input disabled type="text" class="form-control" placeholder="cth. amoxicilin">
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                    </div>
                    <div class="col-md-5">
                        <div class="head_panel_blue">
                        <div class="d-flex justify-content-between align-items-center">
                            <h3>OBAT RUTIN </h3>
                            <div>
                            <b-form-radio-group
                                id="has_obat_rutin"
                                :options="Config.mr.yesNoOpt"
                                v-model="isParent.row.arankaa_has_obat_rutin"
                            />

                            <span class="text-warning d-none" id="has_obat_rutin-typing">User Lain Sedang Mengisi ...</span>            
                            <VValidate 
                                name="Obat Rutin" 
                                v-model="isParent.row.arankaa_has_obat_rutin" 
                                :rules="toValidate(isParent.mrValidation.arankaa_has_obat_rutin)"
                            />
                            </div>
                        </div>
                        </div>

                        <div class="row mt-2" v-if="isParent.row.arankaa_has_obat_rutin == 'Y'">
                        <div class="col-md-12">
                            <div class="form-group">
                            <label>Keterangan </label>
                            <b-form-textarea id="keterangan_obat_rutin" v-model="isParent.row.arankaa_keterangan_obat_rutin" rows="5" cols="3" class="form-control" placeholder=""
                                spellcheck="false"></b-form-textarea>
                            </div>

                            <span class="text-warning d-none" id="keterangan_obat_rutin-typing">User Lain Sedang Mengisi ...</span>          
                            
                            <VValidate 
                                name="Keterangan" 
                                v-model="isParent.row.arankaa_keterangan_obat_rutin" 
                                :rules="toValidate(isParent.mrValidation.arankaa_keterangan_obat_rutin)"
                            />
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">APGAR</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-12">
                          <table class="table table-bordered table-hover pain-scale-table">
                            <thead>
                              <tr>
                                <th colspan="2">Keterangan</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th class="table-warning">A</th>
                                <th class="table-warning">Appearance (Warna kulit)</th>
                                <td v-for="(v,k) in (isParent.Config.mr.mApgar_appereance||[])" :key="k+'arankaa_apgar_appereance'">
                                    <b-form-radio v-model="isParent.row.arankaa_apgar_appereance" name="arankaa_apgar_appereance" :value="v.value">{{v.text}}</b-form-radio>
                                </td>
                              </tr>

                              <tr>
                                <th class="table-warning">P</th>
                                <th class="table-warning">Pulse (Detak jantung)</th>
                                <td v-for="(v,k) in (isParent.Config.mr.mApgar_pulse||[])" :key="k+'arankaa_apgar_pulse'">
                                    <b-form-radio v-model="isParent.row.arankaa_apgar_pulse" name="arankaa_apgar_pulse" :value="v.value">{{v.text}}</b-form-radio>
                                </td>
                              </tr>

                              <tr>
                                <th class="table-warning">G</th>
                                <th class="table-warning">Grimace(Refleks)</th>
                                <td v-for="(v,k) in (isParent.Config.mr.mApgar_grimace||[])" :key="k+'arankaa_apgar_grimace'">
                                    <b-form-radio v-model="isParent.row.arankaa_apgar_grimace" name="arankaa_apgar_grimace" :value="v.value">{{v.text}}</b-form-radio>
                                </td>
                              </tr>

                              <tr>
                                <th class="table-warning">A</th>
                                <th class="table-warning">Activity(Tonus otot)</th>
                                <td v-for="(v,k) in (isParent.Config.mr.mApgar_activity||[])" :key="k+'arankaa_apgar_activity'">
                                    <b-form-radio v-model="isParent.row.arankaa_apgar_activity" name="arankaa_apgar_activity" :value="v.value">{{v.text}}</b-form-radio>
                                </td>
                              </tr>

                              <tr>
                                <th class="table-warning">R</th>
                                <th class="table-warning">Respiration(Usaha bernapas)</th>
                                <td v-for="(v,k) in (isParent.Config.mr.mApgar_respiration||[])" :key="k+'arankaa_apgar_respiration'">
                                    <b-form-radio v-model="isParent.row.arankaa_apgar_respiration" name="arankaa_apgar_respiration" :value="v.value">{{v.text}}</b-form-radio>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr class="table-info">
                                <td colspan="5" class="text-info-700">
                                  <span class="text-uppercase">SCORE APGAR: {{isParent.row.arankaa_apgar_score||0}}</span>
                                  <span v-if="isParent.row.arankaa_apgar_score < 3" class="border-left ml-2 pl-2 font-weight-semibold"> Asfika Berat </span>
                                  <span v-else-if="isParent.row.arankaa_apgar_score >= 4 && isParent.row.arankaa_apgar_score <= 6" class="border-left ml-2 pl-2 font-weight-semibold"> Asfika Ringan-Sedang </span>
                                  <span v-else class="border-left ml-2 pl-2 font-weight-semibold"> Tidak Asfika </span>
                                </td>
                                <!-- Penilaian APGAR
                                 7 - 11 = Risiko Rendah
                                 >= 12 = Risiko Tinggi
                                 -->
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div class="col-md-3 mt-2">
                          <div class="form-group">
                            <label>Usia Gestasi<small class="txt_mandatory">*</small></label>
                            <div class="input-group">
                              <b-form-input :formatter="number" v-model="isParent.row.arankaa_apgar_usia_gestasi" type="text" name="name" class="form-control"/>
                              <div class="input-group-append"><span class="input-group-text">Minggu</span>
                              </div>
                            </div>
                            <VValidate 
                              name="Usia Gestasi" 
                              v-model="isParent.row.arankaa_apgar_usia_gestasi" 
                              :rules="toValidate(isParent.mrValidation.arankaa_apgar_usia_gestasi)"
                            />
                          </div>
                        </div>

                        <div class="col-md-3 mt-2">
                          <div class="form-group">
                            <label>Berat Badan lahir<small class="txt_mandatory">*</small></label>
                            <div class="input-group">
                              <b-form-input :formatter="number" v-model="isParent.row.arankaa_apgar_bbl" type="text" name="name" class="form-control"/>
                              <div class="input-group-append"><span class="input-group-text">gram</span>
                              </div>
                            </div>
                            <VValidate 
                              name="Berat Badan Lahir" 
                              v-model="isParent.row.arankaa_apgar_bbl" 
                              :rules="toValidate(isParent.mrValidation.arankaa_apgar_bbl)"
                            />
                          </div>
                        </div>
                        <div class="col-md-3 mt-2">
                          <div class="form-group">
                            <label>Panjang Badan Lahir<small class="txt_mandatory">*</small></label>
                            <div class="input-group">
                              <b-form-input :formatter="number" v-model="isParent.row.arankaa_apgar_pbl" type="text" name="name" class="form-control"/>
                              <div class="input-group-append"><span class="input-group-text">cm</span>
                              </div>
                            </div>
                            <VValidate 
                              name="Panjang Badan Lahir" 
                              v-model="isParent.row.arankaa_apgar_pbl" 
                              :rules="toValidate(isParent.mrValidation.arankaa_apgar_pbl)"
                            />
                          </div>
                        </div>
                        <div class="col-md-3 mt-2">
                          <div class="form-group">
                            <label>Lain-lain</label>
                            <b-form-input v-model="isParent.row.arankaa_apgar_lainnya" type="text" name="name" class="form-control"/>
                          </div>
                        </div>
                        
                        <div class="col-md-3 mt-2">
                          <div class="form-group">
                            <label>Imunisasi</label>
                            <b-form-input v-model="isParent.row.arankaa_kondisi_riwayat_saat_imunisasi" type="text" name="name" class="form-control"/>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
            </div>
        
            <div class="col-12">
                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                    </div> 
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                            <label>Tekanan Darah</label>
                            <div class="input-group">
                                <b-form-input id="tekanan_darah_min" @blur="onBlur($event, 'tekanan-darah-min')" @focus="onFocus($event, 'tekanan-darah-min')" @input="onInput($event, 'tekanan-darah-min');isParent.autofill($event,'arankkp_ttv_tekanan_darah_min')" :disabled="disabled['tekanan-darah-min']"
                                :formatter="number" v-model="isParent.row.arankaa_ttv_tekanan_darah_min" type="text" name="name" class="form-control" placeholder="Systole"/>
                                
                                <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>

                                <b-form-input id="tekanan_darah_max" @blur="onBlur($event, 'tekanan-darah-max')" @focus="onFocus($event, 'tekanan-darah-max')" @input="onInput($event, 'tekanan-darah-max');isParent.autofill($event,'arankkp_ttv_tekanan_darah_max')" :disabled="disabled['tekanan-darah-max']" :formatter="number" placeholder="Diastole" v-model="isParent.row.arankaa_ttv_tekanan_darah_max" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                            </div>
                            
                            <p class='m-0 text-muted' v-if="disabled['tekanan-darah-min'] || disabled['tekanan-darah-max']">User lain sedang mengisi...</p>

                            <VValidate 
                                name="Tekanan Darah Min" 
                                v-model="isParent.row.arankaa_ttv_tekanan_darah_min" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_tekanan_darah_min)"
                            />
                            <VValidate 
                                name="Tekanan Darah Max" 
                                v-model="isParent.row.arankaa_ttv_tekanan_darah_max" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_tekanan_darah_max)"
                            />
                            </div>
                        </div>

                        <div class="col-md-5">
                            <div class="form-group">
                                <label>Nadi <span class="text-danger">*</span></label>
                                <div class="form-row">
                                    <div class="col-md-12">
                                    <div class="input-group">
                                        <b-form-input id="nadi" @blur="onBlur($event, 'nadi')" @focus="onFocus($event, 'nadi')" @input="onInput($event, 'nadi');isParent.autofill($event,'arankkp_ttv_nadi')" :disabled="disabled['nadi']" :formatter="number" v-model="isParent.row.arankaa_ttv_nadi" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                        <div class="input-group-append">
                                            <div style="width: 140px;">
                                            <v-select id="label" @close="onBlur($event, 'nadi-label')" @open="onFocus($event, 'nadi-label')" @input="onInput($event, 'nadi-label');isParent.autofill($event,'arankkp_ttv_label')" :disabled="disabled['nadi-label']" placeholder="Pilih Label" v-model="isParent.row.arankaa_ttv_label" :options="Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <p class='m-0 text-muted' v-if="disabled['nadi'] || disabled['nadi-label']">User lain sedang mengisi...</p>                                        

                                <VValidate 
                                    name="Nadi" 
                                    v-model="isParent.row.arankaa_ttv_nadi" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_ttv_nadi)"
                                />
                                <VValidate 
                                    name="Label" 
                                    v-model="isParent.row.arankaa_ttv_label" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_ttv_label)"
                                />
                            </div>
                        </div>
                        
                        <div class="col-md-3">
                            <div class="form-group">
                            <label>Gula Darah</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="gula_darah" @blur="onBlur($event, 'gula-darah')" @focus="onFocus($event, 'gula-darah')" @input="onInput($event, 'gula-darah');isParent.autofill($event,'arankkp_ttv_gula_darah')" :disabled="disabled['gula-darah']" :formatter="alphanum" v-model="isParent.row.arankaa_ttv_gula_darah"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['gula-darah']">User lain sedang mengisi...</p>
                            
                            <VValidate 
                                name="Gula Darah" 
                                v-model="isParent.row.arankaa_ttv_gula_darah" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_gula_darah)"
                            />
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Pernafasan<span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <b-form-input id="pernafasan" @blur="onBlur($event, 'pernafasan')" @focus="onFocus($event, 'pernafasan')" @input="onInput($event, 'pernafasan');isParent.autofill($event,'arankkp_ttv_pernafasan')" :disabled="disabled['pernafasan']" :formatter="number" v-model="isParent.row.arankaa_ttv_pernafasan"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                </div>

                                <p class='m-0 text-muted' v-if="disabled['pernafasan']">User lain sedang mengisi...</p>

                                <VValidate 
                                    name="Pernafasan" 
                                    v-model="isParent.row.arankaa_ttv_pernafasan" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_ttv_pernafasan)"
                                />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>SPO2</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="spo2" @blur="onBlur($event, 'spo2')" @focus="onFocus($event, 'spo2')" @input="onInput($event, 'spo2');isParent.autofill($event,'arankkp_ttv_spo2')" :disabled="disabled['spo2']" :formatter="number" v-model="isParent.row.arankaa_ttv_spo2"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">%</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                            <p class='m-0 text-muted' v-if="disabled['spo2']">User lain sedang mengisi...</p>
                            <VValidate 
                                name="SPO2" 
                                v-model="isParent.row.arankaa_ttv_spo2" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_spo2)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Suhu<span class="text-danger">*</span></label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="suhu" @blur="onBlur($event, 'suhu')" @focus="onFocus($event, 'suhu')" @input="onInput($event, 'suhu');isParent.autofill($event,'arankkp_ttv_suhu')" :disabled="disabled['suhu']" :formatter="number" v-model="isParent.row.arankaa_ttv_suhu"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">C</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['suhu']">User lain sedang mengisi...</p>
                            <VValidate 
                                name="Suhu" 
                                v-model="isParent.row.arankaa_ttv_suhu" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_suhu)"
                            />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Berat Badan</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="weight" @blur="onBlur($event, 'berat-badan')" @focus="onFocus($event, 'berat-badan')" :disabled="disabled['berat-badan']" @input="hitungBMI();onInput($event, 'berat-badan');isParent.autofill($event,'arankkp_ttv_weight')" :formatter="number" v-model="isParent.row.arankaa_ttv_weight"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">kg</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['berat-badan']">User lain sedang mengisi...</p>
                            <VValidate 
                                name="Berat Badan" 
                                v-model="isParent.row.arankaa_ttv_weight" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_weight)"
                            />
                            </div>
                        </div>

                        
                        <div class="col-md-2">
                            <div class="form-group">
                            <label>Tinggi</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="height" @blur="onBlur($event, 'tinggi')" @focus="onFocus($event, 'tinggi')" :disabled="disabled['tinggi']" @input="hitungBMI();onInput($event, 'tinggi');isParent.autofill($event,'arankkp_ttv_height')" :formatter="number" v-model="isParent.row.arankaa_ttv_height"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['tinggi']">User lain sedang mengisi...</p>
                            <VValidate 
                                name="Tinggi Badan" 
                                v-model="isParent.row.arankaa_ttv_height" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_height)"
                            />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>Lingkar Kepala</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input id="lingkar_kepala" @blur="onBlur($event, 'lingkar-kepala')" @focus="onFocus($event, 'lingkar-kepala')" :disabled="disabled['lingkar-kepala']" @input="hitungBMI();onInput($event, 'lingkar-kepala');isParent.autofill($event,'arankkp_ttv_lingkar_kepala')"
                                    
                                    :formatter="number" v-model="isParent.row.arankaa_ttv_lingkar_kepala"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">cm</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <p class='m-0 text-muted' v-if="disabled['lingkar-kepala']">User lain sedang mengisi...</p>

                            <VValidate 
                                name="Lingkar Kepala" 
                                v-model="isParent.row.arankaa_ttv_lingkar_kepala" 
                                :rules="toValidate(isParent.mrValidation.arankaa_ttv_lingkar_kepala)"
                            />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>BMI</label>
                            <div class="form-row">
                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input disabled v-model="isParent.row.arankaa_ttv_bmi"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">m2</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div class="col-md-3" v-if="isParent.row.ap_usia <= 15">
                            <div class="form-group">
                            <label>Luas Permukaan Tubuh Anak</label>
                            <div class="input-group">
                                <b-form-input id="permukaan_anak" @blur="onBlur($event, 'permukaan-anak')" @focus="onFocus($event, 'permukaan-anak')" @input="onInput($event, 'permukaan-anak');isParent.autofill($event,'arankkp_ttv_luas_permukaan_anak')" :disabled="disabled['permukaan-anak']" :formatter="number" v-model="isParent.row.arankaa_ttv_luas_permukaan_anak"  type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
                            </div>
                            </div>
                            
                            <p class='m-0 text-muted' v-if="disabled['permukaan-anak']">User lain sedang mengisi...</p>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                            <label>Kesadaran</label>
                            <button type="button" @click="isOpenTS=true" class="btn btn-light mr-3">Isi Tingkat Kesadaran</button>
                            <template v-if="isParent.row.aranktk_nilai_gcs">
                                <div class="">
                                <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                </div>
                                
                                <div class="">
                                    <span class="font-weight-semibold mr-3">E : {{isParent.row.aranktk_response_mata||"-"}}</span>
                                    <span class="font-weight-semibold mr-3">M : {{isParent.row.aranktk_response_motorik||"-"}}</span>
                                    <span class="font-weight-semibold mr-3">V : {{isParent.row.aranktk_response_verbal||"-"}}</span>
                                </div>
                            </template>
                            
                            <VValidate 
                                name="Kesadaran" 
                                v-model="isParent.row.aranktk_nilai_gcs" 
                                :rules="{required : 1}"
                            />
                            </div>
                        </div>

                        <!--
                        <div class="col-md-3" v-if="!isParent.row.arankaa_ttv_bmi">
                            <div class="form-group">
                                <label>IMT</label>
                                <b-form-input v-model="isParent.row.arankaa_ttv_imt" type="text"
                                class="form-control" />
                            </div>
                        </div>
                        -->

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pemeriksaan Penunjang</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col-md-12">
                            <h6>Hasil Pemeriksaan Lab</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Hasil</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.dataHasilLab||[])" :key="k+'hasil'">
                                    <td>{{v.value}}</td>
                                    <td>
                                        <div class="result_tab form-group mb-0">
                                        <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(isParent.dataHasilLab||[]).length">
                                    <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-md-12 mt-3">
                            <h6>Hasil Pemeriksaan Radiologi</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Hasil</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.dataHasilRadiologi||[])" :key="k+'hasil'">
                                    <td>{{v.value}}</td>
                                    <td>
                                        <div class="result_tab form-group mb-0">
                                        <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(isParent.dataHasilRadiologi||[]).length">
                                    <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-12">
                            <h6>Hasil Pemeriksaan Lab Dokter</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Hasil</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.dataHasilLabDokter||[])" :key="k+'hasil'">
                                    <td>{{v.value}}</td>
                                    <td>
                                        <div class="result_tab form-group mb-0">
                                        <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(isParent.dataHasilLabDokter||[]).length">
                                    <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-md-12 mt-3">
                            <h6>Hasil Pemeriksaan Radiologi Dokter</h6>
                            <table class="table table-bordered table-striped table-sm patient-table">
                                <thead>
                                <tr>
                                    <th>Hasil Pemeriksaan </th>
                                    <th>Hasil</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (isParent.dataHasilRadiologiDokter||[])" :key="k+'hasil'">
                                    <td>{{v.value}}</td>
                                    <td>
                                        <div class="result_tab form-group mb-0">
                                        <a class="btn_view" :href="isParent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(isParent.dataHasilRadiologiDokter||[]).length">
                                    <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
            <div class="col-12">
                <div class="card mb-0">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Riwayat Kehamilan Ibu</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                        <label for="MasukRuangIntensive">Pemeriksaan Kehamilan Antenatal Care (ANC)<strong
                            class="text-danger">*</strong></label>
                        <div>
                            <b-form-radio-group
                                :options="isParent.Config.mr.configTeratur"
                                v-model="isParent.row.arankaa_anc"
                            />
                            <VValidate 
                                name="Pemeriksaan Kehamilan" 
                                v-model="isParent.row.arankaa_anc" 
                                :rules="toValidate(isParent.mrValidation.arankaa_anc)"
                            />
                           
                        </div>
                        </div>
                    </div>


                    <div class="col-md-8">
                        <div class="form-group">
                        <label for="MasukRuangIntensive">Tempat pemeriksaan ANC<strong
                            class="text-danger">*</strong></label>
                        <div>
                            <div class="form-check mb-2 form-check-inline align-middle">
                            <b-form-radio-group
                                :options="isParent.Config.mr.configPemeriksaanANC"
                                v-model="isParent.row.arankaa_anc_oleh"
                            />
                            <VValidate 
                                name="Tempat pemeriksaan ANC" 
                                v-model="isParent.row.arankaa_anc_oleh" 
                                :rules="toValidate(isParent.mrValidation.arankaa_anc_oleh)"
                            />
                           
                            <div class="ml-2" v-if="isParent.row.arankaa_anc_oleh == 'Lainnya'">
                                <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_anc_oleh_lainnya"  type="text" class="form-control" placeholder="Lainnya"/>
                                <VValidate 
                                    name="Tempat pemeriksaan Lainnya" 
                                    v-model="isParent.row.arankaa_anc_oleh_lainnya" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_anc_oleh_lainnya)"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="form-group">
                            <label for="MasukRuangIntensive">Komplikasi Kehamilan<strong
                            class="text-danger">*</strong></label>
                            <div>
                                <div>
                              
                                    <b-form-checkbox-group
                                        class="checkbox-inline"
                                        :options="isParent.Config.mr.configKomplikasiANC"
                                        v-model="isParent.row.arankaa_komposisi_kehamilan"
                                    />
                                    
                                    <div class="mt-2" v-if="(isParent.row.arankaa_komposisi_kehamilan||[]).indexOf('Alergi') != -1">
                                        <b-form-input style="width: 300px;" :formatter="normalText" v-model="isParent.row.arankaa_komposisi_kehamilan_alergi"  type="text" class="form-control" placeholder="Alergi"/>
                                        <VValidate 
                                            name="Komplikasi Kehamilan Pasien Alergi" 
                                            v-model="isParent.row.arankaa_komposisi_kehamilan_alergi" 
                                            :rules="toValidate(isParent.mrValidation.arankaa_komposisi_kehamilan_alergi)"
                                        />
                                    </div>

                                    <div class="mt-2" v-if="(isParent.row.arankaa_komposisi_kehamilan||[]).indexOf('Lainnya') != -1">
                                        <b-form-input style="width: 300px;" :formatter="normalText" v-model="isParent.row.arankaa_komposisi_kehamilan_lainnya"  type="text" class="form-control" placeholder="Lainnya"/>
                                        <VValidate 
                                            name="Komplikasi Kehamilan Pasien Lainnya" 
                                            v-model="isParent.row.arankaa_komposisi_kehamilan_lainnya" 
                                            :rules="toValidate(isParent.mrValidation.arankaa_komposisi_kehamilan_lainnya)"
                                        />
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="form-group">
                            <h6 class="card-title font-weight-semibold">Riwayat Kehamilan Ibu</h6>
                            
                            <div class="row">
                                <div class="col-md-12">
                                        
                                    <label for="MasukRuangIntensive">Cara Persalinan<strong
                                    class="text-danger">*</strong></label><br/>

                                    <div class="form-check mb-2 form-check-inline align-middle">
                                    <b-form-radio-group
                                        :options="isParent.Config.mr.configPengobatanIbu"
                                        v-model="isParent.row.arankaa_riwayat_pengobatan_ibu"
                                    />
                                    <VValidate 
                                        name="Cara Persalinan ANC" 
                                        v-model="isParent.row.arankaa_riwayat_pengobatan_ibu" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_riwayat_pengobatan_ibu)"
                                    />
                                
                                    <div class="ml-2" v-if="isParent.row.arankaa_riwayat_pengobatan_ibu == 'Lainnya'">
                                        <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_riwayat_pengobatan_ibu_lainnya"  type="text" class="form-control" placeholder="Lainnya"/>
                                        <VValidate 
                                            name="Cara Persalinan Lainnya" 
                                            v-model="isParent.row.arankaa_riwayat_pengobatan_ibu_lainnya" 
                                            :rules="toValidate(isParent.mrValidation.arankaa_riwayat_pengobatan_ibu_lainnya)"
                                        />
                                    </div>
                                    </div>

                                </div>
                            </div>

                            <div class="row">
                                <label for="MasukRuangIntensive" class="col-md-12">Status Kehamilan<strong
                                    class="text-danger">*</strong></label>
                                <div class="col-md-4">
                                    <div>
                                    <label for="perGravid">Gravid<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="number" v-model="isParent.row.arankaa_gravid"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Gravid" 
                                        v-model="isParent.row.arankaa_gravid" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_gravid)"
                                    />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div>
                                    <label for="perPara">Para<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="number" v-model="isParent.row.arankaa_para"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Para" 
                                        v-model="isParent.row.arankaa_para" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_para)"
                                    />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div>
                                    <label for="perAbortus">Abortus<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="number" v-model="isParent.row.arankaa_abortus"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Abortus" 
                                        v-model="isParent.row.arankaa_abortus" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_abortus)"
                                    />
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <label for="MasukRuangIntensive" class="col-md-12">Riwayat Persalinan yang Lalu<strong
                                    class="text-danger">*</strong></label>
                                <div class="col-md-4">
                                    <div>
                                    <label>Komplikasi Persalinan<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_komplikasi_persalinan_text"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Komplikasi Persalinan" 
                                        v-model="isParent.row.arankaa_komplikasi_persalinan_text" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_komplikasi_persalinan_text)"
                                    />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div>
                                    <label>Kondisi Persalinan<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_kondisi_persalinan_text"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Kondisi Persalinan" 
                                        v-model="isParent.row.arankaa_kondisi_persalinan_text" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_kondisi_persalinan_text)"
                                    />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div>
                                    <label>Kondisi Riwayat Saat Ini<strong class="text-danger">*</strong></label>
                                    <b-form-input :formatter="normalText" v-model="isParent.row.arankaa_kondisi_riwayat_saat_ini"  type="text" class="form-control" />
                                    <VValidate 
                                        name="Kondisi Riwayat Saat Ini" 
                                        v-model="isParent.row.arankaa_kondisi_riwayat_saat_ini" 
                                        :rules="toValidate(isParent.mrValidation.arankaa_kondisi_riwayat_saat_ini)"
                                    />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-12">
                    <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Identifikasi Bayi</h6>
                        </div>
                        <div class="card-body p-3">
                        <div class="row">

                            <div class="col-md-3">
                            <div class="form-group">
                                <label>Nama Ibu <strong
                                    class="text-danger">*</strong></label>
                                <b-form-input v-model="isParent.row.arankaa_identifikasi_ibu_nama" :formatter="isParent.normalText" type="text" name="perKeluhan" class="form-control" placeholder="Nama Ibu" />

                                <VValidate 
                                    name="Nama Ibu" 
                                    v-model="isParent.row.arankaa_identifikasi_ibu_nama" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_identifikasi_ibu_nama)"
                                />
                            </div>
                            </div>

                            <div class="col-md-2">
                            <div class="form-group">
                                <label>Umur Ibu <strong
                                    class="text-danger">*</strong></label>
                                <div class="input-group">
                                <b-form-input v-model="isParent.row.arankaa_identifikasi_ibu_umur" :formatter="isParent.numberOnly" type="text" name="perKeluhan" class="form-control" placeholder="Umur Ibu" />
                                <div class="input-group-append"><span class="input-group-text">Tahun</span>
                                </div>
                                
                                <VValidate 
                                    name="Umur Ibu" 
                                    v-model="isParent.row.arankaa_identifikasi_ibu_umur" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_identifikasi_ibu_umur)"
                                />
                                </div>
                            </div>
                            </div>

                            <div class="col-md-3">
                            <div class="form-group">
                                <label>Nama Ayah <strong
                                    class="text-danger">*</strong></label>
                                <b-form-input v-model="isParent.row.arankaa_identifikasi_ayah_nama" :formatter="isParent.normalText" type="text" name="perKeluhan" class="form-control" placeholder="Nama Ayah" />
                                
                                <VValidate 
                                    name="Nama Ayah" 
                                    v-model="isParent.row.arankaa_identifikasi_ayah_nama" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_identifikasi_ayah_nama)"
                                />
                            </div>
                            </div>
                    
                            
                            <div class="col-md-3">
                                <div class="form-group">
                                <label for="perHamil">Agama Orang Tua <strong
                                    class="text-danger">*</strong></label>
                                <v-select placeholder="Pilih Agama" v-model="isParent.row.arankaa_identifikasi_agama_ortu" :options="isParent.mAgama" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                <VValidate 
                                    name="Agama Orang Tua" 
                                    v-model="isParent.row.arankaa_identifikasi_agama_ortu" 
                                    :rules="{required: 1}"
                                />
                                </div>
                            </div>
                            <div class="col-md-3" v-if="isParent.row.arankaa_identifikasi_agama_ortu  == 99999">
                                <div class="form-group">
                                <label for="perHamil">Agama Orang Tua Lainnya <strong class="text-danger">*</strong></label>
                                <b-form-input v-model="isParent.row.arankaa_identifikasi_agama_ortu_text" :formatter="normalText" type="text" name="perKeluhan" class="form-control" placeholder="Agama Orang Tua Lainnya" />
                                <VValidate 
                                    name="Agama Orang Tua Lainnya" 
                                    v-model="isParent.row.arankaa_identifikasi_agama_ortu_text" 
                                    :rules="{required: 1}"
                                />
                                </div>
                            </div>

                            <div class="col-md-6">
                            <div>
                                <label for="perRiwayatPengobatan">Diagnosa Medis<strong
                                    class="text-danger">*</strong></label>
                                <textarea v-model="isParent.row.arankaa_identifikasi_diagnosa_medis" name="perRiwayatPengobatan" id="perRiwayatPengobatan" rows="2"
                                class="form-control" placeholder="Diagnosa Medis"></textarea>

                                <VValidate 
                                    name="Diagnosa Medis" 
                                    v-model="isParent.row.arankaa_identifikasi_diagnosa_medis" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_identifikasi_diagnosa_medis)"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <!-- Status Kehamilan, muncul hanya jika jenis kelamin adalah perempuan -->
                    <div class="col-12">
                    <div class="card">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Riwayat Sosial & Spiritual</h6>
                        </div>
                        <div class="card-body p-3">
                        <div class="row">
                            <div class="col-md-12">
                            <h6 class="font-weight-semibold text-indigo">Hubungan orang Tua dan bayi (tingkah laku): </h6>
                            </div>
                            <div class="col-md-4">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Ibu<strong class="text-danger">*</strong></label>
                                <div>
                                  <b-form-group>
                                      <b-form-checkbox-group
                                        class="checkbox-inline"
                                        v-model="isParent.row.arankaa_hub_ibu"
                                        :options="Config.mr.mHubunganRanap"
                                      ></b-form-checkbox-group>
                                  </b-form-group>
                                </div>
                            </div>
                            </div>

                            <div class="col-md-4">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Ayah<strong class="text-danger">*</strong></label>
                                <div>
                                  <b-form-group>
                                      <b-form-checkbox-group
                                        class="checkbox-inline"
                                        v-model="isParent.row.arankaa_hub_ayah"
                                        :options="Config.mr.mHubunganRanap"
                                      ></b-form-checkbox-group>
                                  </b-form-group>
                                </div>
                            </div>
                            </div>

                            <div class="col-md-12">
                            <hr>
                            </div>

                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Asuhan Terminal</label>
                                <div>
                                  <b-form-radio-group
                                      :options="isParent.Config.mr.yesNoOpt"
                                      v-model="isParent.row.arankaa_asuhan_terminal"
                                  />
                                </div>
                            </div>
                            </div>
                            
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="MasukRuangIntensive">Kebutuhan Spiritual keluarga pasien dalam perawatan di
                                    Rumah Sakit<span class="text-danger" v-if="isParent.row.arankaa_asuhan_terminal == 'Y'">*</span></label>
                                    <div>
                                        <b-form-radio-group
                                            :options="isParent.Config.mr.mRKDiperlukanDlmHal"
                                            v-model="isParent.row.arankaa_kebutuhan_spiritual"
                                        />
                                        <VValidate 
                                            v-if="isParent.row.arankaa_asuhan_terminal == 'Y'"
                                            name="Kebutuhan Spiritual keluarga pasien dalam perawatan di Rumah Sakit" 
                                            v-model="isParent.row.arankaa_kebutuhan_spiritual" 
                                            :rules="{required: 1}"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="MasukRuangIntensive">Keinginan keluarga untuk DNR terhadap pasien<span class="text-danger" v-if="isParent.row.arankaa_asuhan_terminal == 'Y'">*</span></label>
                                    <div>
                                        <b-form-radio-group
                                            :options="isParent.Config.mr.yesNoOpt"
                                            v-model="isParent.row.arankaa_keinginan_dnr"
                                        />
                                        <VValidate 
                                            v-if="isParent.row.arankaa_asuhan_terminal == 'Y'"
                                            name="Keinginan keluarga untuk DNR terhadap pasien" 
                                            v-model="isParent.row.arankaa_keinginan_dnr" 
                                            :rules="{required: 1}"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <!--
                    <div class="col-12">
                    <div class="card">
                        <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Rencana Kepulangan</h6>
                        </div>
                        <div class="card-body p-3">
                        <div class="row">
                            <div class="col-md-12">
                            <h6 class="font-weight-semibold text-indigo">Pengaruh rawat inap terhadap: </h6>
                            </div>
                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Pasien dan keluarga pasien<strong
                                    class="text-danger">*</strong></label>
                                <b-form-radio-group
                                    :options="isParent.Config.mr.yesNoOpt"
                                    v-model="isParent.row.arankaa_rk_pasien_keluarga"
                                />
                                <VValidate 
                                  name="Pasien dan keluarga pasien" 
                                  v-model="isParent.row.arankaa_rk_pasien_keluarga" 
                                  :rules="toValidate(isParent.mrValidation.arankaa_rk_pasien_keluarga)"
                                />
                            </div>
                            </div>
                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Pekerjaan<strong class="text-danger">*</strong></label>
                                <div>
                                
                                <b-form-radio-group
                                    :options="isParent.Config.mr.yesNoOpt"
                                    v-model="isParent.row.arankaa_rk_pekerjaan"
                                />
                                <VValidate 
                                  name="Penagruh Pekerjaan" 
                                  v-model="isParent.row.arankaa_rk_pekerjaan" 
                                  :rules="toValidate(isParent.mrValidation.arankaa_rk_pekerjaan)"
                                />

                                </div>
                            </div>
                            </div>

                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Keuangan<strong class="text-danger">*</strong></label>
                                <div>
                                  <b-form-radio-group
                                      :options="isParent.Config.mr.yesNoOpt"
                                      v-model="isParent.row.arankaa_rk_keuangan"
                                  />
                                  <VValidate 
                                    name="Keuangan" 
                                    v-model="isParent.row.arankaa_rk_keuangan" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_rk_keuangan)"
                                  />
                                </div>
                            </div>
                            </div>


                            <div class="col-md-12">
                            <hr>
                            </div>

                            <div class="col-md-3">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Antisipasi terhadap masalah saat pulang<strong
                                    class="text-danger">*</strong></label>
                                <div>
                                  <b-form-radio-group
                                      :options="isParent.Config.mr.yesNoOpt"
                                      v-model="isParent.row.arankaa_rk_antisipasi"
                                  />
                                  <VValidate 
                                    name="Antisipasi terhadap masalah saat pulang" 
                                    v-model="isParent.row.arankaa_rk_antisipasi" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_rk_antisipasi)"
                                  />
                                </div>
                            </div>
                            </div>




                            <div class="col-md-4">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Bantuan diperlukan dalam hal<strong
                                    class="text-danger">*</strong></label>
                                <div>
                                  <b-form-group>
                                      <b-form-checkbox-group
                                        class="checkbox-inline"
                                        v-model="isParent.row.arankaa_bantuan"
                                        :options="Config.mr.mBantuanDiperlukanDalamHal"
                                      ></b-form-checkbox-group>
                                  </b-form-group>
                                </div>
                            </div>
                            </div>



                            <div class="col-md-4">
                            <div class="form-group">
                                <label for="MasukRuangIntensive">Adakah yang membantu keperluan tersebut diatas? <strong
                                    class="text-danger">*</strong></label>
                                <div>
                                  <b-form-radio-group
                                      :options="isParent.Config.mr.yesNoOpt"
                                      v-model="isParent.row.arankaa_keperluan_diatas"
                                  />
                                  <VValidate 
                                    name="Adakah yang membantu keperluan tersebut diatas?" 
                                    v-model="isParent.row.arankaa_keperluan_diatas" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_keperluan_diatas)"
                                  />
                                </div>
                                <div class="mt-2" v-if="isParent.row.arankaa_keperluan_diatas == 'Y'">
                                  <b-form-input v-model="isParent.row.arankaa_keperluan_diatas_text" :formatter="isParent.normalText" type="text" class="form-control" placeholder="Sebutkan" />
                                  <VValidate 
                                    name="Pembantu Keperluan" 
                                    v-model="isParent.row.arankaa_keperluan_diatas_text" 
                                    :rules="toValidate(isParent.mrValidation.arankaa_keperluan_diatas_text)"
                                  />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    -->

                    </div>
                </div>
                </div>
            </div>
            
            <b-modal v-model="isOpenTS" :title="'Isi Tingkat Kesadaran'" size="xl" ok-only ok-title="Simpan">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header bg-info">
                                <h5 class="card-title font-weight-semibold">Tingkat Kesadaran (GCS)</h5>
                            </div>
                            <div class="card-body">
                                <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                    <th>Pengkajian</th>
                                    <th>Nilai</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td class="table-warning" rowspan="4"><span class="font-weight-semibold">Respons Mata</span></td>
                                    
                                    <!-- ele pertama hardcode -->
                                    <td>
                                    <b-form-radio @input="onInput($event, 'response-mata');isParent.autofill($event,'arankkp_tingkat_kesadaran_mata')" v-model="isParent.row.aranktk_response_mata" name="aranktk_response_mata" :value="4">Dapat Membuka Mata Secara Spontan  <span class="text-blue-800 font-weight-semibold">(4)</span></b-form-radio>
                                    </td>

                                    </tr>
                                    <template v-for="(v,k) in (Config.mr.ksResponseMata||[])">
                                        <tr v-if="k != 0" :key="k">
                                        <td>
                                            <b-form-radio @input="onInput($event, 'response-mata');isParent.autofill($event,'arankkp_tingkat_kesadaran_mata')" v-model="isParent.row.aranktk_response_mata" name="aranktk_response_mata" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                        </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody>
                                    <tr>
                                    <td class="table-warning" rowspan="5"><span class="font-weight-semibold">Respons Verbal</span></td>
                                    
                                    <td>
                                    <b-form-radio @input="onInput($event, 'response-verbal');isParent.autofill($event,'arankkp_tingkat_kesadaran_verbal')" v-model="isParent.row.aranktk_response_verbal" name="aranktk_response_verbal" :value="5">Pasien Sadar dan Merespons Pertanyaan dengan Benar<span class="text-blue-800 font-weight-semibold">(5)</span>
                                    </b-form-radio>
                                    </td>
                                    
                                    </tr>
                                    <template v-for="(v,k) in (Config.mr.ksResponseVerbal||[])">
                                        <tr v-if="k != 0" :key="k">
                                        <td>
                                            <b-form-radio @input="onInput($event, 'response-verbal');isParent.autofill($event,'arankkp_tingkat_kesadaran_verbal')" v-model="isParent.row.aranktk_response_verbal" name="aranktk_response_verbal" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                        </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody>
                                    <tr>
                                    <td class="table-warning" rowspan="6"><span class="font-weight-semibold">Respons Motorik</span></td>
                                    
                                    <td>
                                    <b-form-radio @input="onInput($event, 'response-motorik');isParent.autofill($event,'arankkp_tingkat_kesadaran_motorik')" v-model="isParent.row.aranktk_response_motorik" name="aranktk_response_motorik" :value="6">Pasien Dapat Melakukan Gerakan Sesuai Perintah<span class="text-blue-800 font-weight-semibold">(6)</span></b-form-radio>
                                    </td>
                                    
                                    </tr>
                                    <template v-for="(v,k) in (Config.mr.ksResponseMotorik||[])">
                                        <tr v-if="k != 0" :key="k">
                                        <td>
                                            <b-form-radio @input="onInput($event, 'response-motorik');isParent.autofill($event,'arankkp_tingkat_kesadaran_motorik')" v-model="isParent.row.aranktk_response_motorik" name="aranktk_response_motorik" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                        </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tfoot>
                                    <tr>
                                    <td class="table-info" colspan="9">
                                        <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                        <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                        <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                        <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                        <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                        <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                        <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                        <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                    </td>
                                    </tr>
                                </tfoot>
                                </table>

                                <!--
                                <VValidate 
                                    name="Response Mata" 
                                    v-model="isParent.row.aranktk_response_mata" 
                                    :rules="toValidate(isParent.mrValidation.aranktk_response_mata)"
                                    class="d-block"
                                />

                                
                                <VValidate 
                                    name="Response Verbal" 
                                    v-model="isParent.row.aranktk_response_verbal" 
                                    :rules="toValidate(isParent.mrValidation.aranktk_response_verbal)"
                                    class="d-block"
                                />
                                
                                <VValidate 
                                    name="Response Motorik" 
                                    v-model="isParent.row.aranktk_response_motorik" 
                                    :rules="toValidate(isParent.mrValidation.aranktk_response_motorik)"
                                    class="d-block"
                                />
                                -->


                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
    extends: GlobalVue,
    data(){
        return {
            isOpenTS: false,
            disabled: {
                'keluhan-utama': false,
                'riwayat-penyakit-dahulu': false,
                'catatan-dokter': false,
                'alergi-jenis': [],
                'alergi-name': [],
                'tekanan-darah-min': false,
                'tekanan-darah-max': false,
                'nadi': false,
                'nadi-label': false,
                'gula-darah': false,
                'pernafasa': false,
                'spo2': false,
                'suhu': false,
                'berat-badan': false,
                'tinggi': false,
                'lingkar-kepala': false,
                'permukaan-anak': false,
                'pupil': false,
            }
        }
    },
    mounted(){
        this.sockets.subscribe('collaborate_kajian-awal', data => {
            if(data.regId != this.$route.query.regId){
                return
            }

            if(data.type == 'set-disable'){
                if(data.field == 'alergi-jenis'){
                    this.$set(this.disabled['alergi-jenis'], data.index, true)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.disabled['alergi-name'], data.index, true)
                }
                else{
                    this.$set(this.disabled, data.field, true)
                }
            }
            else if(data.type == 'set-enable'){
                if(data.field == 'alergi-jenis'){
                    this.$set(this.disabled['alergi-jenis'], data.index, false)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.disabled['alergi-name'], data.index, false)
                }
                else{
                    this.$set(this.disabled, data.field, false)
                }
            }
            else if(data.type == 'set-value'){
                if(data.field == 'keluhan-utama'){
                    this.$set(this.isParent.row, 'arankaa_keluhan_utama', data.value)
                }
                else if(data.field == 'catatan-dokter'){
                    this.$set(this.isParent.row, 'arankaa_riwayat_penyakit_sekarang', data.value)
                }
                else if(data.field == 'riwayat-penyakit-dahulu'){
                    this.$set(this.isParent.row, 'arankaa_riwayat_penyakit', data.value)
                }
                else if(data.field == 'has-alergi'){
                    this.$set(this.isParent.row, 'arankaa_has_alergi', data.value)
                    if(!(this.isParent.row.arankaa_alergi||[]).length){
                        this.addAlergi()
                    }
                }
                else if(data.field == 'alergi-jenis'){
                    this.$set(this.isParent.row.arankaa_alergi[data.index], 'jenis', data.value)
                }
                else if(data.field == 'alergi-name'){
                    this.$set(this.isParent.row.arankaa_alergi[data.index], 'name', data.value)
                }
                else if(data.field == 'tekanan-darah-min'){
                    this.$set(this.isParent.row, 'arankaa_ttv_tekanan_darah_min', data.value)
                }
                else if(data.field == 'tekanan-darah-max'){
                    this.$set(this.isParent.row, 'arankaa_ttv_tekanan_darah_max', data.value)
                }
                else if(data.field == 'nadi'){
                    this.$set(this.isParent.row, 'arankaa_ttv_nadi', data.value)
                }
                else if(data.field == 'nadi-label'){
                    this.$set(this.isParent.row, 'arankaa_ttv_label', data.value)
                }
                else if(data.field == 'gula-darah'){
                    this.$set(this.isParent.row, 'arankaa_ttv_gula_darah', data.value)
                }
                else if(data.field == 'pernafasan'){
                    this.$set(this.isParent.row, 'arankaa_ttv_pernafasan', data.value)
                }
                else if(data.field == 'spo2'){
                    this.$set(this.isParent.row, 'arankaa_ttv_spo2', data.value)
                }
                else if(data.field == 'suhu'){
                    this.$set(this.isParent.row, 'arankaa_ttv_suhu', data.value)
                }
                else if(data.field == 'berat-badan'){
                    this.$set(this.isParent.row, 'arankaa_ttv_weight', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'tinggi'){
                    this.$set(this.isParent.row, 'arankaa_ttv_height', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'lingkar-kepala'){
                    this.$set(this.isParent.row, 'arankaa_ttv_lingkar_kepala', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'permukaan-anak'){
                    this.$set(this.isParent.row, 'arankaa_ttv_luas_permukaan_anak', data.value)
                    this.hitungBMI()
                }
                else if(data.field == 'response-mata'){
                    this.$set(this.isParent.row, 'aranktk_response_mata', data.value)
                }
                else if(data.field == 'response-verbal'){
                    this.$set(this.isParent.row, 'aranktk_response_verbal', data.value)
                }
                else if(data.field == 'response-motorik'){
                    this.$set(this.isParent.row, 'aranktk_response_motorik', data.value)
                }
                else if(data.field == 'pupil'){
                    this.$set(this.isParent.row, 'arankaa_ttv_pupil', data.value)
                }
            }
            else if(data.type == 'delete-alergi'){
                this.isParent.row.arankaa_alergi.splice(data.index,1)
            }
            else if(data.type == 'add-alergi'){
                this.isParent.row.arankaa_alergi.push(data.value)
            }

            console.log(data)
        })
    },
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        }, 
        totalAPGAR(){
            let total
            total = (this.isParent.row.arankaa_apgar_appereance + this.isParent.row.arankaa_apgar_pulse + this.isParent.row.arankaa_apgar_grimace + this.isParent.row.arankaa_apgar_activity + this.isParent.row.arankaa_apgar_respiration||0)
            return total
        },
        tingkatKesadaranTotal(){
            return (this.isParent.row.aranktk_response_mata||0) + 
            (this.isParent.row.aranktk_response_verbal||0) + 
            (this.isParent.row.aranktk_response_motorik||0)
        },
    },
    methods: {
        onFocus(v, field, index = false){
            const regId = this.$route.query.regId
            console.log(v)
            const payload = {
                to: 'kajian-awal',
                type: 'set-disable',
                field: field,
                regId: regId,
                index: index
            }
            this.$socket.emit('collaborate', payload)
        },
        onBlur(v, field, index = false){
            const regId = this.$route.query.regId
            const payload = {
                to: 'kajian-awal',
                type: 'set-enable',
                field: field,
                regId: regId,
                index: index
            }
            this.$socket.emit('collaborate', payload)
        },
        onInput: _.debounce(function(v, field, index = false){
            const regId = this.$route.query.regId
            const payload = {
                to: 'kajian-awal',
                type: 'set-value',
                field: field,
                regId: regId,
                value: v,
                index: index
            }

            this.$socket.emit('collaborate', payload)
        }, 500),

        addRiwayatPenggunaanObat(){
            this.isParent.row.arankaa_riwayat_penggunaan_obat.push({
                'nama': null,
                'dosis': null,
                'penggunaan': null,
            })
        },
        toValidate(val){
            return {...val}
        },
        hitungBMI(){
            if(this.isParent.row.arankaa_ttv_weight && this.isParent.row.arankaa_ttv_height){
                this.isParent.row.arankaa_ttv_bmi = this.isParent.row.arankaa_ttv_weight / ((this.isParent.row.arankaa_ttv_height/100) * (this.isParent.row.arankaa_ttv_height/100))
                this.isParent.row.arankaa_ttv_bmi = this.isParent.row.arankaa_ttv_bmi.toFixed(2) 
            }else{
                this.isParent.row.arankaa_ttv_bmi = null 
            }
        },
        
        searchAlergi: _.debounce(function (e) {
          let data = {
              name: e,
              type: 'get-alergi'
          }
          Gen.apiRest(
              "/do/"+this.isParent.modulePage,
              {data:data}, 
              "POST"
          ).then(res=>{
              this.isParent.isParent.mAlergi = res.data.data            
          })
        }, 100),
        
        changeAlergi(e){
            if(!(this.isParent.row.arankaa_alergi||[]).length){
                this.addAlergi()
            }
        },
        
        addAlergi(){
            const data = {
                name : '',
                jenis : null,
            }
            if(!Array.isArray(this.isParent.row.arankaa_alergi)){
                this.$set(this.isParent.row, 'arankaa_alergi', [])
            }

            this.isParent.row.arankaa_alergi.push(data)
            this.addMasterAlergi()
            const payload = {
                to: 'kajian-awal',
                type: 'add-alergi',
                regId: this.$route.query.regId,
                value: data
            }

            this.$socket.emit('collaborate', payload)
        },
        
        addMasterAlergi(){
            for(let i = 0; i < (this.isParent.row.arankaa_alergi||[]).length; i++){
                let newData = this.isParent.row.arankaa_alergi[i]
                if((newData.name||"").length && newData.jenis == "Obat"){
                    let dataPost = {
                        type : 'auto-save-riwayat',
                        name : newData.name
                    }  
                    Gen.apiRest(
                        "/do/"+this.isParent.modulePage,
                        {data:dataPost}, 
                        "POST"
                    )
                }
            }
        },
        
        removeAlergi(k){
            if(!Array.isArray(this.isParent.row.arankaa_alergi)){
                this.$set(this.isParent.row, 'arankaa_alergi', [])
            }

            this.isParent.row.arankaa_alergi.splice(k,1)
            const payload = {
                to: 'kajian-awal',
                type: 'delete-alergi',
                regId: this.$route.query.regId,
                index: k
            }

            this.$socket.emit('collaborate', payload)
        },
        
        inputPsikolog(e){
            if(this.isParent.row.arankaa_status_psikologi == 6){
                this.isParent.row.arankaa_status_psikologi_text = ''
            }
        },

        changeHPL() {
          let dateHPL = moment(this.isParent.row.arankaa_hpht, "DD-MM-YYYY").format('YYYY-M-D')
          let splDate = dateHPL.split("-")
          let day = splDate[2]
          let month = splDate[1]
          let year = splDate[0]

          day = +day + 7
          if (month == 1) {
            month = 10
          } else if (month == 2) {
            month = 11
          } else if (month == 3) {
            month = 12
          } else {
            month = +month - 3
          }
          year = +year + 1

          let daysOfMonth = moment(+year + "-" + month + "-01").endOf("month").format("D")
          daysOfMonth = +daysOfMonth

          let newDay

          if (day > daysOfMonth) {
            newDay = day - daysOfMonth
            month = month + 1
          } else {
            newDay = day
          }

          let newdateHPL = moment((newDay + '-' + month + '-' + year), "DD-MM-YYYY").format('YYYY-MM-DD')

          this.isParent.row.arankaa_hpl = newdateHPL
        },
        
        hitungBMIRes(){
            if(this.isParent.row.arankkp_ttv_weight && this.isParent.row.arankkp_ttv_height){
                this.isParent.row.arankkp_ttv_bmi = this.isParent.row.arankkp_ttv_weight / this.isParent.row.arankkp_ttv_height
                this.isParent.row.arankkp_ttv_bmi = this.isParent.row.arankkp_ttv_bmi.toFixed(2) 
            }else{
                this.isParent.row.arankkp_ttv_bmi = null 
            }
        },
    },
    watch:{
        tingkatKesadaranTotal(v){
            setTimeout(()=>{
                this.isParent.row.aranktk_nilai_gcs = v
            },250)
        },
        totalAPGAR(v){
            setTimeout(()=>{
                this.isParent.row.arankaa_apgar_score = v
            },250)
        }
    },

    destroyed(){
        this.sockets.unsubscribe('collaborate_kajian-awal')
    }
}

</script>