<template>
  <div>
    <div class="row">
      <div class="col-6 mb-3">
        <div class="card mb-0">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Airway</h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-12">
                <div class="signsCheck">
                  <div class="row">
                  
                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_air_tidak_ada_masalah" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Tidak ada masalah
                      </b-form-checkbox>
                    </div>

                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_air_bunyi_nafas" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Perubahan bunyi nafas
                      </b-form-checkbox>
                      <div class="ml-2" v-if="isParent.row.arankkf_air_bunyi_nafas == 'Y'">
                        <b-form-input v-model="isParent.row.arankkf_air_bunyi_nafas_text" type="text" class="form-control" />
                        <VValidate 
                            :name="'Ket. Bunyi Nafas'" 
                            v-model="isParent.row.arankkf_air_bunyi_nafas_text" 
                            :rules="{required : 1}"
                            class="d-block"
                        />
                      </div>
                    </div>
                    
                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_air_gangguan_menelan" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Gangguan menelan
                      </b-form-checkbox>
                    </div>
                    
                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_air_residu" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Peningkatan residu lambung
                      </b-form-checkbox>
                    </div>
                    
                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_air_motilitas" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Penurunan motilitas usus
                      </b-form-checkbox>
                    </div>
                    
                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_air_adanya_selang" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Adanya selang tracheostomy atau endotrakea (TT/ET)
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card mb-0">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Breathing</h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-12">
                <div class="signsCheck">
                  <div class="row">
                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_bre_tidak_ada_masalah" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Tidak ada masalah
                      </b-form-checkbox>
                    </div>
                    
                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_bre_penggunaan_otot_nafas" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Penggunaan otot bantu nafas
                      </b-form-checkbox>
                    </div>
                    
                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_bre_nilai_apgar" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Nilai APGAR
                      </b-form-checkbox>
                      <div class="ml-2" v-if="isParent.row.arankkf_bre_nilai_apgar == 'Y'">
                        <b-form-input v-model="isParent.row.arankkf_bre_nilai_apgar_text" type="text" class="form-control" />
                        <VValidate 
                            :name="'Ket. Nilai APGAR'" 
                            v-model="isParent.row.arankkf_bre_nilai_apgar_text" 
                            :rules="{required : 1}"
                            class="d-block"
                        />
                      </div>
                    </div>

                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_bre_sianosis" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Sianosis
                      </b-form-checkbox>
                    </div>
                    
                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_bre_alat_bantu_nafas" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Penggunaan alat bantu nafas
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 mb-2">
        <div class="card mb-0">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Circulation</h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-12">
                <div class="signsCheck">
                  <div>
                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_cir_akral" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Akral
                      </b-form-checkbox>
                      <div class="ml-2" v-if="isParent.row.arankkf_cir_akral == 'Y'">
                        <b-form-input v-model="isParent.row.arankkf_cir_akral_text" type="text" class="form-control" />
                        <VValidate 
                            :name="'Ket. Akral'" 
                            v-model="isParent.row.arankkf_cir_akral_text" 
                            :rules="{required : 1}"
                            class="d-block"
                        />
                      </div>
                    </div>

                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_cir_pucat" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Pucat
                      </b-form-checkbox>
                    </div>

                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_cir_sianosis_kuku" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Sianosis Pada Kuku
                      </b-form-checkbox>
                    </div>

                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_cir_sianosis_mata" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Sianosis Sekitar Mata
                      </b-form-checkbox>
                    </div>

                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_cir_sianosis_mulut" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Sianosis Sekitar Mulut
                      </b-form-checkbox>
                    </div>


                    <div class="form-check-inline align-middle col-12 ml-2 mb-1">
                      <b-form-checkbox v-model="isParent.row.arankkf_cir_sianosis_kapiler" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Pengisian Kapiler
                      </b-form-checkbox>
                      <div class="ml-2" v-if="isParent.row.arankkf_cir_sianosis_kapiler == 'Y'">
                        <b-form-input v-model="isParent.row.arankkf_cir_sianosis_kapiler_text" type="text" class="form-control" />
                        <VValidate 
                            :name="'Ket. Pengisian Kapiler'" 
                            v-model="isParent.row.arankkf_cir_sianosis_kapiler_text" 
                            :rules="{required : 1}"
                            class="d-block"
                        />
                      </div>
                    </div>

                    <div class="form-check-inline align-middle col-12 ml-2">
                      <b-form-checkbox v-model="isParent.row.arankkf_cir_sianosis_pendarahan" value="Y"
                      unchecked-value="N" class="form-check-input-styled p-0">
                      Perdarahan
                      </b-form-checkbox>
                      <div class="ml-2" v-if="isParent.row.arankkf_cir_sianosis_pendarahan == 'Y'">
                        <b-form-input v-model="isParent.row.arankkf_cir_sianosis_pendarahan_text" type="text" class="form-control" />
                        <VValidate 
                            :name="'Ket. Perdarahan'" 
                            v-model="isParent.row.arankkf_cir_sianosis_pendarahan_text" 
                            :rules="{required : 1}"
                            class="d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header bg-info">
        <h5 class="card-title font-weight-semibold">Pengkajian Fisik</h5>
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td class="table-warning"><span class="font-weight-semibold">Kepala</span></td>
              <td>
                <b-form-radio-group
                  :options="isParent.Config.mr.ranapKAFisikKepala"
                  v-model="isParent.row.arankkf_kepala"
                />
              </td>
            </tr>

            <tr>
              <td class="table-warning"><span class="font-weight-semibold">Mata</span></td>
              <td>
                <b-form-checkbox-group
                    name="mata"
                    class="checkbox-inline"
                    v-model="isParent.row.arankkf_mata"
                    :options="isParent.Config.mr.ranapKAFisikMata"
                ></b-form-checkbox-group>

                <table class="table mt-3 table-borderedtable-sm patient-table">
                  <tbody>
                    <tr>
                      <th><span class="font-weight-semibold">Pupil Kanan</span></th>
                      <td>
                        <div class="form-group">
                          <label>Reflek Cahaya</label>
                          <v-select placeholder="-- Pilih Salah Satu --" 
                          v-model="isParent.row.arankkf_mata_kanan_reflek_cahaya" :options="isParent.Config.mr.plusMin" 
                          label="text" :reduce="v=>v.value"></v-select>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <label>Diameter</label>
                          <v-select placeholder="-- Pilih Salah Satu --" 
                          v-model="isParent.row.arankkf_mata_kanan_diameter" :options="isParent.Config.mr.ranapPupilDiameter" 
                          label="text" :reduce="v=>v.value"></v-select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th><span class="font-weight-semibold">Pupil Kiri</span></th>
                      <td>
                        <div class="form-group">
                          <label>Reflek Cahaya</label>
                          <v-select placeholder="-- Pilih Salah Satu --" 
                          v-model="isParent.row.arankkf_mata_kiri_reflek_cahaya" :options="isParent.Config.mr.plusMin" 
                          label="text" :reduce="v=>v.value"></v-select>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <label>Diameter</label>
                          <v-select placeholder="-- Pilih Salah Satu --" 
                          v-model="isParent.row.arankkf_mata_kiri_diameter" :options="isParent.Config.mr.ranapPupilDiameter" 
                          label="text" :reduce="v=>v.value"></v-select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td class="table-warning"><span class="font-weight-semibold">Hidung</span></td>
              <td class="d-flex">
                <b-form-radio-group
                  :options="isParent.Config.mr.ranapKAFisikHidung"
                  v-model="isParent.row.arankkf_hidung"
                />
                <div class="ml-2" v-if="isParent.row.arankkf_hidung == 'Lainnya'">
                  <b-form-input v-model="isParent.row.arankkf_hidung_text" type="text" class="form-control" placeholder="Lainnya" />
                  <VValidate 
                    :name="'Hidung'" 
                    v-model="isParent.row.arankkf_hidung_text" 
                    :rules="{required : 1}"
                    class="d-block"
                  />
                </div>
              </td>
            </tr>


            <tr>
              <td class="table-warning"><span class="font-weight-semibold">Mulut</span></td>
              <td class="d-flex">
                <b-form-radio-group
                  :options="isParent.Config.mr.ranapKAFisikMulut"
                  v-model="isParent.row.arankkf_mulut"
                />
                <div class="ml-2" v-if="isParent.row.arankkf_mulut == 'Lainnya'">
                  <b-form-input v-model="isParent.row.arankkf_mulut_text" type="text" class="form-control" placeholder="Lainnya" />
                  <VValidate 
                    :name="'Mulut'" 
                    v-model="isParent.row.arankkf_mulut_text" 
                    :rules="{required : 1}"
                    class="d-block"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td class="table-warning"><span class="font-weight-semibold">Mukosa mulut</span></td>
              <td class="d-flex">
                <b-form-radio-group
                  :options="isParent.Config.mr.ranapKAFisikMulutMukosa"
                  v-model="isParent.row.arankkf_mukosa_mulut"
                />
                <div class="ml-2" v-if="isParent.row.arankkf_mukosa_mulut == 'Lainnya'">
                  <b-form-input v-model="isParent.row.arankkf_mukosa_mulut_text" type="text" class="form-control" placeholder="Lainnya" />
                  <VValidate 
                    :name="'Mukosa Mulut'" 
                    v-model="isParent.row.arankkf_mukosa_mulut_text" 
                    :rules="{required : 1}"
                    class="d-block"
                  />
                </div>
              </td>
            </tr>


            <tr>
              <td class="table-warning"><span class="font-weight-semibold">Dada</span></td>
              <td class="d-flex">
                <b-form-radio-group
                  :options="isParent.Config.mr.ranapKAFisikDada"
                  v-model="isParent.row.arankkf_dada"
                />
                <div class="ml-2" v-if="isParent.row.arankkf_dada == 'Lainnya'">
                  <b-form-input v-model="isParent.row.arankkf_dada_text" type="text" class="form-control" placeholder="Lainnya" />
                  <VValidate 
                    :name="'Dada'" 
                    v-model="isParent.row.arankkf_dada_text" 
                    :rules="{required : 1}"
                    class="d-block"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td class="table-warning"><span class="font-weight-semibold">Tonus Otot</span></td>
              <td>
                <b-form-textarea v-model="isParent.row.arankkf_tonus_otot" rows="2" class="form-control"></b-form-textarea>
              </td>
            </tr>

            <tr>
              <td class="table-warning"><span class="font-weight-semibold">Anus</span></td>
              <td>
                <b-form-textarea v-model="isParent.row.arankkf_anus" rows="2" class="form-control"></b-form-textarea>
              </td>
            </tr>

            <tr>
              <td class="table-warning"><span class="font-weight-semibold">Genital</span></td>
              <td>
                <b-form-textarea v-model="isParent.row.arankkf_genital" rows="2" class="form-control"></b-form-textarea>
              </td>
            </tr>
            <tr>
              <td class="table-warning"><span class="font-weight-semibold">Warna kulit</span></td>
              <td>
                 <b-form-radio-group
                  :options="isParent.Config.mr.ranapKAWarnaKulit"
                  v-model="isParent.row.arankkf_warna_kulit"
                />
              </td>
            </tr>
            <tr>
              <td class="table-warning"><span class="font-weight-semibold">Tanda Lahir </span></td>
              <td>
                <b-form-textarea v-model="isParent.row.arankkf_tanda_kulit" rows="2" class="form-control"></b-form-textarea>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default {
  extends: GlobalVue,
  computed: {
    isParent(){
        return this.$parent.$parent.$parent.$parent
    },
  },
  methods: {
    toValidate(val){
      return {...val}
    },  
  }
}
</script>